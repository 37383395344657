
















































































































































import { Component, Vue } from "vue-property-decorator";
import {getList,getListOne} from '@/serve/traditionalMedicine/index'
export interface TraditionalForm{
  current?: string | number;
  size?: string | number;
  checkerName?: string;
  checkerIdCard?: string;
  quesStartTime?: any;
  quesEndTime?: any;
  tenantId?: string;
}
@Component({
  name: "traditionalMedicine",
  components: {},
})
export default class TraditionalMedicine extends Vue {
  private currentPage4 = 1;
  private form: TraditionalForm = {
    current:1,
    size:10,
  };
  //弹框
  private dialogFormVisible = false;
  //详情数据
  private detailList: any = [];
  private isAdmin = sessionStorage.getItem('isAdmin');
  private tenantName = '';
  created(){
    console.log('-----TraditionalMedicine-----');
    //获取问卷列表
    this.obtionList()
  }
  //获取数据
  private obtionList(){
    getList(this.form).then((res: any) => {
      if(res.code === 200){
        this.tableData = res.data
      }
    })
  }
  //表格数据
  private tableData = [];
  // 筛选功能
  private onSubmit() {
    // 点击筛选后 初始化值
    this.currentPage4 = 1;
    this.form.size = 10;
    this.form.current = 1;
    this.obtionList();
  }
  // 重置功能
  private resetForm(formName: string) {
    (this.$refs[formName] as any).resetFields();
    this.currentPage4 = 1;
    this.obtionList();
  }
  private getContent(arr: any[]){
    let str = ''
      arr.map((i: any)=>{
        str += i.schemeContent
      })
    return str
  }
  private checkerName = ''
  //点击查看详细信息
  private readMore(id: number,name: string){
    this.detailList = []
    getListOne({questionnaireId:id}).then((res: any) => {
      if(res.code === 200){
        this.dialogFormVisible = true
        this.detailList = res.data
        this.checkerName = name
      }
    })
  }
  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.obtionList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.obtionList();
  }
}

import request from '../../utils/request'


//获取问卷列表
export function getList(data: any){
  return request({
    method:'GET',
    type:'params',
    url:'manage/physiqueQuestionnaire/pageList',
    data:data
  })
}
//获取单条问卷明细
export function getListOne(data: any){
  return request({
    method:'GET',
    type:'params',
    url:'manage/physiqueQuestionnaire/detail',
    data:data
  })
}

//获取问卷列表
export function getMindList(data: any){
  return request({
    method:'GET',
    type:'params',
    url:'manage/questionnaire/SCLResultPageList',
    data:data
  })
}

// 答卷记录列表
export function getResultPageList(data: any) {
  return request({
    method: 'GET',
    type: 'params',
    url: 'manage/questionnaire/resultPageList',
    data: data
  })
}
// 答卷记录详情
export function getResultDetail(data: any) {
  return request({
    method: 'GET',
    type: 'params',
    url: 'manage/questionnaire/resultDetail',
    data: data
  })
}